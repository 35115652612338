import React, { useEffect, useState } from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    IconButton,
    Snackbar,
    Alert as MuiAlert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { styled } from '@mui/system';
import { useOutletContext } from 'react-router-dom';
import RequireAuth from '../components/RequireAuth';
import { format } from 'date-fns';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    margin: '10px 0',
    boxShadow: 'none',
    border: '1px solid #ddd',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
    '& .MuiAccordionSummary-content': {
        alignItems: 'center',
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#fafafa',
    padding: '20px',
}));

const HighlightedText = ({ text, highlight }) => {
    if (!highlight.trim()) {
        return <span>{text}</span>;
    }

    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    return (
        <span>
            {parts.map((part, index) =>
                regex.test(part) ? (
                    <span key={index} style={{ backgroundColor: 'yellow' }}>
                        {part}
                    </span>
                ) : (
                    <span key={index}>{part}</span>
                )
            )}
        </span>
    );
};

const Favorites = ({ api }) => {
    const { searchQuery, selectedLanguage, selectedStatus, selectedYear } = useOutletContext();
    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [favoriteToRemove, setFavoriteToRemove] = useState(null);
    const [references, setReferences] = useState({});

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const token = localStorage.getItem('access_token');
                if (!token) {
                    setSnackbarMessage('You must be logged in to view favorites.');
                    setSnackbarSeverity('warning');
                    setSnackbarOpen(true);
                    return;
                }

                const response = await fetch(`${api}/favorites`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                // Handle subscription expiration or unauthorized access
                if (response.status === 401) {
                    setSnackbarMessage('Unauthorized. Please log in again.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    return;
                } else if (response.status === 403) {
                    // Subscription expired or not active
                    setSnackbarMessage('Your subscription has expired. Please renew to view favorites.');
                    setSnackbarSeverity('warning');
                    setLoading(false)
                    setSnackbarOpen(true);
                    return;
                }

                const data = await response.json();
                setFavorites(data);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching favorites:', error);
                setSnackbarMessage('Error fetching favorites.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setLoading(false);
            }


        };

        const fetchReferences = async () => {
            try {
                const response = await fetch(`${api}/references`);
                const data = await response.json();
                setReferences(data.reduce((acc, ref) => {
                    acc[ref.id] = ref;
                    return acc;
                }, {}));
            } catch (error) {
                console.error('Error fetching references:', error);
            }
        };
        fetchFavorites();
        fetchReferences();
    }, [api]);

    const handleFavorite = (itemId, itemType) => {
        const isFavorited = favorites.find(fav => fav.item_id === itemId && fav.item_type === itemType);

        if (isFavorited) {
            setFavoriteToRemove({ itemId, itemType });
            setConfirmDialogOpen(true);
        } else {
            toggleFavorite(itemId, itemType, false);
        }
    };

    const toggleFavorite = async (itemId, itemType, isRemoval) => {
        const token = localStorage.getItem('access_token');

        if (!token) {
            setSnackbarMessage('You must be logged in to favorite items.');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }

        try {
            const response = await fetch(`${api}/favorites/${isRemoval ? 'remove' : 'add'}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ item_id: itemId, item_type: itemType })
            });

            if (response.status === 401) {
                setSnackbarMessage('Unauthorized. Please log in again.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return;
            }

            if (response.ok) {
                setFavorites(prev => isRemoval ? prev.filter(fav => !(fav.item_id === itemId && fav.item_type === itemType)) : [...prev, { item_id: itemId, item_type: itemType }]);
                setSnackbarMessage(isRemoval ? 'Removed from favorites!' : 'Added to favorites!');
                setSnackbarSeverity('success');
            } else {
                setSnackbarMessage('Failed to update favorites.');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage('Error updating favorites.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
            setConfirmDialogOpen(false);
        }
    };

    const handleConfirmRemove = () => {
        if (favoriteToRemove) {
            toggleFavorite(favoriteToRemove.itemId, favoriteToRemove.itemType, true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (loading) {
        return (
            <RequireAuth>
                <Container>
                    <CircularProgress />
                </Container>
            </RequireAuth>
        );
    }

    const favoriteChapters = favorites.filter(fav => fav.item_type === 'chapter');
    const favoriteSections = favorites.filter(fav => fav.item_type === 'section');
    const favoriteArticles = favorites.filter(fav => fav.item_type === 'article');


    return (
        <RequireAuth>
            <Container sx={{ marginTop: "10px" }}>
                <Typography variant="h4" gutterBottom>
                    My Favorites
                </Typography>
                {favorites.length === 0 ? snackbarMessage === '' ? (
                    <Typography variant="h6">You have no favorites yet.</Typography>) : (<Typography variant="h6">{snackbarMessage}</Typography>
                ) : (
                    <>
                        {favoriteChapters.length > 0 && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Favorite Chapters
                                </Typography>
                                {favoriteChapters.map((fav) => (
                                    <ChapterDetails
                                        key={fav.item_id}
                                        api={api}
                                        chapterId={fav.item_id}
                                        selectedLanguage={selectedLanguage}
                                        handleFavorite={handleFavorite}
                                        favorites={favorites}
                                        searchQuery={searchQuery}
                                        selectedStatus={selectedStatus}
                                        selectedYear={selectedYear}
                                        references={references}
                                    />
                                ))}
                            </>
                        )}

                        {favoriteSections.length > 0 && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Favorite Sections
                                </Typography>
                                {favoriteSections.map((fav) => (
                                    <SectionDetails
                                        key={fav.item_id}
                                        api={api}
                                        sectionId={fav.item_id}
                                        selectedLanguage={selectedLanguage}
                                        handleFavorite={handleFavorite}
                                        favorites={favorites}
                                        searchQuery={searchQuery}
                                        selectedStatus={selectedStatus}
                                        selectedYear={selectedYear}
                                        references={references}
                                    />
                                ))}
                            </>
                        )}

                        {favoriteArticles.length > 0 && (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Favorite Articles
                                </Typography>
                                {favoriteArticles.map((fav) => (
                                    <ArticleDetails
                                        key={fav.item_id}
                                        api={api}
                                        articleId={fav.item_id}
                                        selectedLanguage={selectedLanguage}
                                        handleFavorite={handleFavorite}
                                        favorites={favorites}
                                        searchQuery={searchQuery}
                                        selectedStatus={selectedStatus}
                                        selectedYear={selectedYear}
                                        references={references}
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>

                <Dialog
                    open={confirmDialogOpen}
                    onClose={() => setConfirmDialogOpen(false)}
                    aria-labelledby="confirm-dialog-title"
                    aria-describedby="confirm-dialog-description"
                >
                    <DialogTitle id="confirm-dialog-title">Remove Favorite</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="confirm-dialog-description">
                            Are you sure you want to remove this item from your favorites?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmRemove} color="primary" autoFocus>
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </RequireAuth>
    );
};

const ChapterDetails = ({ api, chapterId, selectedLanguage, handleFavorite, favorites, searchQuery, selectedStatus, selectedYear, references }) => {
    const [chapter, setChapter] = useState(null);
    const [loading, setLoading] = useState(true);

    const filterAndReduceSections = (sections) => {
        if (!Array.isArray(sections)) {
            return [];
        }
        return sections
            .map(section => {
                const filteredArticles = filterAndReduceArticles(section.articles_lst);
                if (filteredArticles.length > 0) {
                    return { ...section, articles_lst: filteredArticles };
                }
                return null;
            })
            .filter(section => section !== null);
    };

    const filterAndReduceArticles = (articles) => {
        return articles
            .filter(article => {
                const articleTranslation = article.translations.find(t => t.language_code === selectedLanguage);
                const articleMatchesStatus = selectedStatus ? article.status === selectedStatus : true;
                const articleMatchesYear = selectedYear ? new Date(article.effective_date).getFullYear() === parseInt(selectedYear, 10) : true;
                const articleMatchesQuery = articleTranslation ? articleTranslation.text.toLowerCase().includes(searchQuery.toLowerCase()) : article.title.toLowerCase().includes(searchQuery.toLowerCase());
                return articleMatchesStatus && articleMatchesYear && articleMatchesQuery;
            });
    };

    useEffect(() => {
        const fetchChapter = async () => {
            try {
                const response = await fetch(`${api}/chapter/${chapterId}/get`);
                if (!response.ok) throw new Error('Failed to fetch chapter');
                const data = await response.json();
                setChapter(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching chapter:', error);
                setLoading(false);
            }
        };

        fetchChapter();
    }, [api, chapterId]);

    if (loading) {
        return <CircularProgress />;
    }

    if (!chapter) {
        return <Typography>Error loading chapter</Typography>;
    }

    const filteredSections = filterAndReduceSections(chapter.sections_lst);
    if (filteredSections.length === 0) {
        return null;
    }

    const translation = chapter.translations.find(t => t.language_code === selectedLanguage);

    return (
        <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                    <HighlightedText text={translation ? translation.text : chapter.name} highlight={searchQuery} />
                    <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(chapterId, 'chapter'); }}>
                        {favorites.some(fav => fav.item_id === chapterId && fav.item_type === 'chapter') ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <Box>
                    {filteredSections.map(section => (
                        <SectionDetails
                            key={section.id}
                            api={api}
                            sectionId={section.id}
                            selectedLanguage={selectedLanguage}
                            handleFavorite={handleFavorite}
                            favorites={favorites}
                            searchQuery={searchQuery}
                            selectedStatus={selectedStatus}
                            selectedYear={selectedYear}
                            references={references}
                        />
                    ))}
                </Box>
            </StyledAccordionDetails>
        </StyledAccordion>
    );
};

const SectionDetails = ({ api, sectionId, selectedLanguage, handleFavorite, favorites, searchQuery, selectedStatus, selectedYear, references }) => {
    const [section, setSection] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSection = async () => {
            try {
                const response = await fetch(`${api}/section/${sectionId}/get`);
                if (!response.ok) throw new Error('Failed to fetch section');
                const data = await response.json();
                setSection(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching section:', error);
                setLoading(false);
            }
        };

        fetchSection();
    }, [api, sectionId]);

    if (loading) {
        return <CircularProgress />;
    }

    if (!section) {
        return <Typography>Error loading section</Typography>;
    }

    const filteredArticles = section.articles_lst
        .filter(article => {
            const articleTranslation = article.translations.find(t => t.language_code === selectedLanguage);
            const articleMatchesStatus = selectedStatus ? article.status === selectedStatus : true;
            const articleMatchesYear = selectedYear ? new Date(article.effective_date).getFullYear() === parseInt(selectedYear, 10) : true;
            const articleMatchesQuery = articleTranslation ? articleTranslation.text.toLowerCase().includes(searchQuery.toLowerCase()) : article.title.toLowerCase().includes(searchQuery.toLowerCase());
            return articleMatchesStatus && articleMatchesYear && articleMatchesQuery;
        });

    if (filteredArticles.length === 0) {
        return null;
    }

    const translation = section.translations.find(t => t.language_code === selectedLanguage);

    return (
        <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                    <HighlightedText text={translation ? translation.text : section.name} highlight={searchQuery} />
                    <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(sectionId, 'section'); }}>
                        {favorites.some(fav => fav.item_id === sectionId && fav.item_type === 'section') ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <Box>
                    {filteredArticles.map(article => (
                        <ArticleDetails
                            key={article.id}
                            api={api}
                            articleId={article.id}
                            selectedLanguage={selectedLanguage}
                            handleFavorite={handleFavorite}
                            favorites={favorites}
                            searchQuery={searchQuery}
                            selectedStatus={selectedStatus}
                            selectedYear={selectedYear}
                            references={references}
                        />
                    ))}
                </Box>
            </StyledAccordionDetails>
        </StyledAccordion>
    );
};

const ArticleDetails = ({ api, articleId, selectedLanguage, handleFavorite, favorites, searchQuery, selectedStatus, selectedYear, references }) => {
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await fetch(`${api}/article/${articleId}/get`);
                if (!response.ok) throw new Error('Failed to fetch article');
                const data = await response.json();
                setArticle(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching article:', error);
                setLoading(false);
            }
        };

        fetchArticle();
    }, [api, articleId]);

    if (loading) {
        return <CircularProgress />;
    }

    if (!article) {
        return <Typography>Error loading article</Typography>;
    }

    const translation = article.translations.find(t => t.language_code === selectedLanguage);

    const matchArticle = translation ? translation.text.toLowerCase().includes(searchQuery.toLowerCase()) : article.title.toLowerCase().includes(searchQuery.toLowerCase());

    return matchArticle ? (
        <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                    <HighlightedText text={translation ? translation.text : article.title} highlight={searchQuery} />
                    <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(articleId, 'article'); }}>
                        {favorites.some(fav => fav.item_id === articleId && fav.item_type === 'article') ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <Box>
                    {translation && <Typography variant="body1"><HighlightedText text={translation.text} highlight={searchQuery} /></Typography>}
                    <Typography variant="body1"><HighlightedText text={translation?.desc || ''} highlight={searchQuery} /></Typography>
                    <Typography variant='h4'>Effective Date: {format(new Date(article.effective_date), 'MMMM dd, yyyy')}</Typography>
                    <Typography variant='h4'>Status: {article.status}</Typography>
                    {article.reference_id && references[article.reference_id] && (
                        <Typography variant='h4'>
                            Reference: <a href={`${api}/reference/${article.reference_id}/download`} target="_blank" rel="noopener noreferrer" style={{ color: 'lightblue' }}>{references[article.reference_id].description}</a>
                        </Typography>
                    )}
                </Box>
            </StyledAccordionDetails>
        </StyledAccordion>
    ) : null;
};

export default Favorites;
